import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useUser } from '../context/UserContext';
import { fetchUserFiles } from '../services/fileService';
import { fetchSessions } from '../services/sessionService';
import ChatMessages from '../components/ChatMessages';
import ChatInput from '../components/ChatInput';
import FileSelection from '../components/FileSelection';
import SessionSelector from '../components/SessionSelector';
import Select from 'react-select';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const options = [
  { value: 'claude-v1', label: 'Claude v1' },
  { value: 'gpt-3.5', label: 'GPT-3.5' },
  { value: 'gpt-4', label: 'GPT-4' },
];

const Chat = () => {
  const { loggedInUser } = useUser();
  const [model, setModel] = useState(options[0]);
  const [reload, setreload] = useState(false);
  const [apiKey, setAPIKey] = useState('');
  const [message, setMessage] = useState('');
  const [activeSessionId, setActiveSessionId] = useState(null);
  const [sessions, setSessions] = useState({});
  const [previousSessions, setPreviousSessions] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [useRag, setUseRag] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState('');
  const [userFiles, setUserFiles] = useState([]);
  const socketRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);

  const updateChatLog = useCallback(
    (sender, text, history = [], isPartial = false) => {
      setSessions((prevSessions) => {
        const currentSession = prevSessions[activeSessionId] || [];
        let updatedSession;

        if (sender === 'Claude') {
          if (isPartial) {
            const lastMessage = currentSession[currentSession.length - 1];
            if (
              lastMessage &&
              lastMessage.sender === 'Claude' &&
              lastMessage.isPartial
            ) {
              updatedSession = [
                ...currentSession.slice(0, currentSession.length - 1),
                { ...lastMessage, text: lastMessage.text + text },
              ];
            } else {
              updatedSession = [
                ...currentSession,
                {
                  sender,
                  text,
                  key: `${sender}-${Math.random().toString(36).substr(2, 9)}`,
                  isPartial: true,
                },
              ];
            }
          } else {
            updatedSession = [
              ...currentSession,
              {
                sender,
                text,
                key: `${sender}-${Math.random().toString(36).substr(2, 9)}`,
                isPartial: false,
              },
            ];
          }
        } else {
          updatedSession = [
            ...currentSession,
            {
              sender,
              text,
              key: `${sender}-${Math.random().toString(36).substr(2, 9)}`,
              isPartial: false,
            },
          ];

          if (updatedSession.length > 1) {
            const lastMessageIndex = updatedSession.length - 2;
            const lastMessage = updatedSession[lastMessageIndex];
            if (lastMessage.sender === 'Claude' && lastMessage.isPartial) {
              updatedSession[lastMessageIndex] = {
                ...lastMessage,
                isPartial: false,
              };
            }
          }
        }

        return {
          ...prevSessions,
          [activeSessionId]: updatedSession,
        };
      });
    },
    [activeSessionId]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (loggedInUser) {
        try {
          const [files, sessionsData] = await Promise.all([
            fetchUserFiles(),
            fetchSessions(),
          ]);
          setUserFiles(files);
          setPreviousSessions(sessionsData);

          if (sessionsData.length > 0) {
            setActiveSessionId(sessionsData[0].session_id);
          } else {
            handleNewSession();
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [loggedInUser, reload]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [sessions, activeSessionId, reload]);

  useEffect(() => {
    if (apiKey && activeSessionId) {
      const socketUrl = `wss://daretools.com/ws/claude/?api_key=${encodeURIComponent(
        apiKey
      )}&session_id=${activeSessionId}`;
      socketRef.current = new WebSocket(socketUrl);

      socketRef.current.onopen = () => {
        console.log('Connected to WebSocket');
      };
      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.history && Array.isArray(data.history)) {
          data.history.forEach((message) => {
            const { user_message, bot_response } = message;

            if (user_message) {
              updateChatLog('You', user_message);
            }
            if (bot_response) {
              updateChatLog('Claude', bot_response, [], false);
            }
          });
        } else if (data.error) {
          console.error('WebSocket Error:', data.error);
        } else if (data.partial_response) {
          updateChatLog('Claude', data.partial_response, [], true);
        } else if (data.response) {
          updateChatLog('Claude', data.response, [], false);
        }
        setIsTyping(false);
        setreload(false);
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      socketRef.current.onclose = () => {
        console.log('WebSocket connection closed');
      };

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [apiKey, activeSessionId, updateChatLog, reload]);

  const handleSendMessage = () => {
    if (
      message.trim() !== '' &&
      socketRef.current &&
      socketRef.current.readyState === WebSocket.OPEN
    ) {
      updateChatLog('You', message);

      const formattedPrompt = ` ${message}`;

      const payload = {
        prompt: formattedPrompt,
        max_tokens_to_sample: 1000,
        stop_sequences: ['\n\n'],
        model: model.value || 'claude-v1',
        use_rag: useRag,
      };

      if (useRag && selectedFileId) {
        payload.file_path = userFiles.find(
          (file) => file.id === Number(selectedFileId)
        )?.file;
      }

      socketRef.current.send(JSON.stringify(payload));
      setMessage('');
      setIsTyping(true);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  };

  const handleNewSession = () => {
    const newSessionId = `session-${Date.now()}`;
    setActiveSessionId(newSessionId);
    setSessions((prevSessions) => ({
      ...prevSessions,
      [newSessionId]: [],
    }));
  };

  const handleSessionSwitch = (sessionId) => {
    setActiveSessionId(sessionId);
    setSessions((prevSessions) => ({
      ...prevSessions,
      [sessionId]: [],
    }));
  };

  return (
    <div className='flex w-full h-[calc(100vh-var(--custom-header-height))]'>
      <div
        className={`flex-none transition-all duration-300 ${
          isMinimized ? 'w-8' : 'w-1/5'
        } border-r border-gray-300 bg-gray-100 relative`}
      >
        <div className={`absolute top-2 right-2 cursor-pointer text-gray-500`}>
          {isMinimized ? (
            <FaChevronRight onClick={() => setIsMinimized(false)} />
          ) : (
            <FaChevronLeft onClick={() => setIsMinimized(true)} />
          )}
        </div>

        {!isMinimized && (
          <>
            <SessionSelector
              previousSessions={previousSessions}
              activeSessionId={activeSessionId}
              handleSessionSwitch={handleSessionSwitch}
            />
            <div className='p-4'>
              <button
                className='bg-green-500 text-white px-3 py-2 rounded w-full'
                onClick={() => {
                  handleNewSession();
                  setreload(true);
                }}
              >
                New Chat
              </button>
            </div>
          </>
        )}
      </div>

      <div className='flex-grow mx-8'>
        <div className='flex items-center justify-start gap-4 my-2'>
          <label
            htmlFor='Use RAG'
            className='font-normal w-16'
          >
            Use RAG
          </label>
          <input
            type='checkbox'
            checked={useRag}
            onChange={(e) => setUseRag(e.target.checked)}
            className='focus:outline-none'
          />
        </div>

        {useRag && (
          <FileSelection
            userFiles={userFiles}
            selectedFileId={selectedFileId}
            setSelectedFileId={setSelectedFileId}
          />
        )}
        <ChatMessages
          sessions={sessions}
          activeSessionId={activeSessionId}
          isTyping={isTyping}
        />

        <div className='flex items-center justify-start gap-4 my-2'>
          <label
            htmlFor='Model'
            className='font-normal w-12'
          >
            Model
          </label>
          <Select
            name='model'
            id='model'
            options={options}
            value={model}
            isDisabled={true}
            onChange={setModel}
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              control: (provided) => ({
                ...provided,
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
              }),
            }}
          />
        </div>

        <ChatInput
          message={message}
          setMessage={setMessage}
          apiKey={apiKey}
          setAPIKey={setAPIKey}
          handleSendMessage={handleSendMessage}
          isDisabled={!apiKey || !message}
        />
      </div>
    </div>
  );
};

export default Chat;
